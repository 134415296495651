<template>
    <v-container fluid>
        <v-row class="text-center">
            <v-col>
                <h2>NCU overview</h2>
            </v-col>
        </v-row>
        <v-row justify="center">
            <v-col cols="12" md="8">
                <v-card flat color="transparent" class="d-flex flex-column" height="100%">
                    <v-card-title class="pt-2">
                        <v-list-item class="list-item pa-0">
                            <v-list-item-avatar>
                                <v-icon large>mdi-memory</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title class="text-h6 secondary--text list-title">NCU utilization</v-list-item-title>
                                <v-list-item-subtitle class="list-subtitle">in hours ({{ ncuUtilizationTimelineTimeUnit }})</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-card-title>
                    <v-card-text class="flex-grow-1">
                        <dashboard-loader v-if="fetchingCharts.ncu_utilization_timeline"></dashboard-loader>
                        <template v-else>
                            <bar-chart
                                v-if="hasData(charts.ncu_utilization_timeline) && !$isError(charts.ncu_utilization_timeline)"
                                :chartData="ncuUtilizationTimeline"
                                :options="{ legend: false, yTitle: 'NCU hours' }" />
                            <dashboard-empty v-if="!hasData(charts.ncu_utilization_timeline)"></dashboard-empty>
                            <dashboard-error v-if="$isError(charts.ncu_utilization_timeline)"></dashboard-error>
                        </template>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" sm="6" md="4">
                <v-card height="100%">
                    <v-card-title class="pt-2">
                        <v-list-item class="list-item pa-0">
                            <v-list-item-avatar>
                                <v-icon large>mdi-memory</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title class="text-h6 secondary--text list-title">NCU utilization</v-list-item-title>
                                <v-list-item-subtitle class="list-subtitle">in hours</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-card-title>
                    <v-card-text class="flex-grow-1 text-center">
                        <dashboard-loader v-if="fetchingCharts.ncu_utilization"></dashboard-loader>
                        <template v-else>
                            <template v-if="hasData(charts.ncu_utilization) && !$isError(charts.ncu_utilization)">
                                <div>A total of</div>
                                <div class="text-h4 secondary--text">{{ totalNcuUtilization }}</div>
                                <div class="pb-8">NCU hours</div>
                                <doughnut-chart :chartData="ncuUtilization" :rpid="rpid" @legendClick="legendClickNcuUtilization" />
                            </template>
                            <dashboard-empty v-if="!hasData(charts.ncu_utilization)"></dashboard-empty>
                            <dashboard-error v-if="$isError(charts.ncu_utilization)"></dashboard-error>
                        </template>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row class="text-center mt-10">
            <v-col>
                <h2>Filesystem overview</h2>
            </v-col>
        </v-row>
        <v-row justify="center">
            <v-col cols="12" md="8">
                <v-card flat color="transparent" height="100%">
                    <v-card-title class="pt-2">
                        <v-list-item class="list-item pa-0">
                            <v-list-item-avatar>
                                <v-icon large>mdi-folder-network-outline</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title class="text-h6 secondary--text list-title">Filesystem utilization</v-list-item-title>
                                <v-list-item-subtitle class="list-subtitle">in GBs ({{ fsUtilizationTimelineTimeUnit }})</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-card-title>
                    <v-card-text class="flex-grow-1">
                        <dashboard-loader v-if="fetchingCharts.fs_utilization_timeline"></dashboard-loader>
                        <template v-else>
                            <template v-if="hasData(charts.fs_utilization_timeline) && !$isError(charts.fs_utilization_timeline)">
                                <bar-chart :chartData="fsUtilizationTimeline" :options="{ legend: false, yTitle: 'GB' }" />
                                <div v-if="showUsageLimitLabel" class="pt-2 text-center">
                                    <v-icon color="#ef7d57">mdi-circle</v-icon>
                                    Usage limit (GB)
                                </div>
                            </template>
                            <dashboard-empty v-if="!hasData(charts.fs_utilization_timeline)"></dashboard-empty>
                            <dashboard-error v-if="$isError(charts.fs_utilization_timeline)"></dashboard-error>
                        </template>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" sm="6" md="4">
                <v-card height="100%">
                    <v-card-title class="pt-2">
                        <v-list-item class="list-item pa-0">
                            <v-list-item-avatar>
                                <v-icon large>mdi-folder-network-outline</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title class="text-h6 secondary--text list-title">Filesystem utilization</v-list-item-title>
                                <v-list-item-subtitle v-if="fsUtilizationDate" class="list-subtitle">in GBs as of {{ fsUtilizationDate }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-card-title>
                    <v-card-text class="flex-grow-1 text-center">
                        <dashboard-loader v-if="fetchingCharts.fs_utilization"></dashboard-loader>
                        <template v-else>
                            <template v-if="hasData(charts.fs_utilization) && !$isError(charts.fs_utilization)">
                                <div>A total of</div>
                                <div class="text-h4 secondary--text">{{ totalFsUtilization }}</div>
                                <div class="pb-8">GBs</div>
                                <doughnut-chart :chartData="fsUtilization" :rpid="rpid" @legendClick="legendClickFsUtilization" />
                            </template>
                            <dashboard-empty v-if="!hasData(charts.fs_utilization)"></dashboard-empty>
                            <dashboard-error v-if="$isError(charts.fs_utilization)"></dashboard-error>
                        </template>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row class="text-center mt-10">
            <v-col>
                <h2>User activity overview</h2>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-card flat color="transparent" height="100%">
                    <v-card-title class="pt-2">
                        <v-list-item class="list-item pa-0">
                            <v-list-item-avatar>
                                <v-icon large>mdi-console</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title class="text-h6 secondary--text list-title">Number of application runs</v-list-item-title>
                                <v-list-item-subtitle v-if="fsUtilizationDate" class="list-subtitle">
                                    ({{ appUtilizationTimelineTimeUnit }})
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-card-title>
                    <v-card-text class="flex-grow-1">
                        <dashboard-loader v-if="fetchingCharts.app_utilization_timeline"></dashboard-loader>
                        <template v-else>
                            <bar-chart
                                v-if="hasData(charts.app_utilization_timeline) && !$isError(charts.app_utilization_timeline)"
                                :chartData="appUtilizationTimeline"
                                :options="{ legend: false, precision: 0, yTitle: 'Application runs' }" />
                            <dashboard-empty v-if="!hasData(charts.app_utilization_timeline)"></dashboard-empty>
                            <dashboard-error v-if="$isError(charts.app_utilization_timeline)"></dashboard-error>
                        </template>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { interpolateColors, isAllNull } from '@/utils'
import * as d3 from 'd3-scale-chromatic'
import { orderBy, sum } from 'lodash'
import { DateTime } from 'luxon'
import dashboard from '@/mixins/dashboard'

const colorScale = d3.interpolateRainbow
const colorRangeInfo = {
    colorStart: 0,
    colorEnd: 1,
    useEndAsStart: false
}

export default {
    name: 'PlanUtilization',
    mixins: [dashboard],
    data() {
        return {
            primaryColor: this.$vuetify.theme.themes.light.primary,
            doughnutChartSlicesLimit: 8,
            fsUtilizationDate: '',
            charts: {
                ncu_utilization: {},
                ncu_utilization_timeline: {},
                fs_utilization: {},
                fs_utilization_timeline: {},
                fs_usage_limit: {},
                app_utilization_timeline: {}
            },
            fetchingCharts: {
                ncu_utilization: false,
                ncu_utilization_timeline: false,
                fs_utilization: false,
                fs_utilization_timeline: false,
                fs_usage_limit: false,
                app_utilization_timeline: false
            },
            precision: this.$appConfig?.VUE_APP_DASHBOARD_PRECISION,
            ignoredNcuUtilizationItems: [],
            ignoredFsUtilizationItems: []
        }
    },
    computed: {
        ncuUtilization() {
            const slices = this.limitDoughnutChartArcs(this.charts.ncu_utilization, this.doughnutChartSlicesLimit)
            const labels = slices.map(slice => slice.name)
            const data = slices.map(slice => slice.value)

            const meta = slices.map(slice => {
                return {
                    oid: slice.oid,
                    sid: slice.sid
                }
            })

            return {
                labels,
                datasets: [
                    {
                        data: this.roundDataItems(data),
                        backgroundColor: this.doughnutBackgroundColors(this.charts.ncu_utilization)
                    }
                ],
                meta
            }
        },
        totalNcuUtilization() {
            let data = this.ncuUtilization.datasets[0].data.map(item => parseFloat(item))
            data = data.filter((item, index) => !this.ignoredNcuUtilizationItems.includes(index))
            return sum(data).toFixed(this.precision)
        },
        ncuUtilizationTimeline() {
            const dataByDate = this.chartDataByDate(this.charts.ncu_utilization_timeline)
            const labels = Object.keys(dataByDate)
            const data = Object.values(dataByDate)

            return {
                labels: this.dateToLabel(labels),
                datasets: [
                    {
                        data: this.roundDataItems(data),
                        backgroundColor: this.primaryColor
                    }
                ]
            }
        },
        ncuUtilizationTimelineTimeUnit() {
            return this.findBarChartGroupingResolution(this.charts.ncu_utilization_timeline)
        },
        fsUtilization() {
            const slices = this.limitDoughnutChartArcs(this.charts.fs_utilization, this.doughnutChartSlicesLimit)
            const labels = slices.map(slice => slice.name)
            const data = slices.map(slice => slice.value)

            const meta = slices.map(slice => {
                return {
                    oid: slice.oid,
                    sid: slice.sid
                }
            })

            return {
                labels,
                datasets: [
                    {
                        data: this.roundDataItems(this.dataInGBs(data)),
                        backgroundColor: this.doughnutBackgroundColors(this.charts.fs_utilization)
                    }
                ],
                meta
            }
        },
        totalFsUtilization() {
            let data = this.fsUtilization.datasets[0].data.map(item => parseFloat(item))
            data = data.filter((item, index) => !this.ignoredFsUtilizationItems.includes(index))
            return sum(data).toFixed(this.precision)
        },
        fsUtilizationTimeline() {
            const dataByDate = this.chartDataByDate(this.charts.fs_utilization_timeline, false)
            const labels = Object.keys(dataByDate)
            const data = Object.values(dataByDate)
            const datasets = []
            datasets.push({
                data: this.roundDataItems(this.dataInGBs(data)),
                backgroundColor: this.primaryColor,
                order: 1
            })
            if (!this.oid) {
                datasets.push({
                    data: this.roundDataItems(this.dataInGBs(Object.values(this.charts.fs_usage_limit))),
                    backgroundColor: '#ef7d57',
                    borderColor: '#ef7d57',
                    type: 'line',
                    order: 0
                })
            }

            return {
                labels: this.dateToLabel(labels),
                datasets
            }
        },
        fsUtilizationTimelineTimeUnit() {
            return this.findBarChartGroupingResolution(this.charts.fs_utilization_timeline)
        },
        appUtilizationTimeline() {
            const dataByDate = this.chartDataByDate(this.charts.app_utilization_timeline)
            const labels = Object.keys(dataByDate)
            const data = Object.values(dataByDate)

            return {
                labels: this.dateToLabel(labels),
                datasets: [
                    {
                        data,
                        backgroundColor: this.primaryColor
                    }
                ]
            }
        },
        appUtilizationTimelineTimeUnit() {
            return this.findBarChartGroupingResolution(this.charts.app_utilization_timeline)
        },
        showUsageLimitLabel() {
            return !this.oid && !isAllNull(Object.values(this.charts.fs_usage_limit))
        }
    },
    methods: {
        legendClickNcuUtilization(item) {
            if (item.hidden) {
                this.ignoredNcuUtilizationItems = this.ignoredNcuUtilizationItems.filter(ignoredItem => ignoredItem !== item.index)
            } else {
                this.ignoredNcuUtilizationItems.push(item.index)
            }
        },
        legendClickFsUtilization(item) {
            if (item.hidden) {
                this.ignoredFsUtilizationItems = this.ignoredFsUtilizationItems.filter(ignoredItem => ignoredItem !== item.index)
            } else {
                this.ignoredFsUtilizationItems.push(item.index)
            }
        },
        doughnutBackgroundColors(data) {
            if (Object.entries(data).length <= this.doughnutChartSlicesLimit) return interpolateColors(Object.entries(data).length, colorScale, colorRangeInfo)

            const colors = interpolateColors(this.doughnutChartSlicesLimit - 1, colorScale, colorRangeInfo)
            colors.push(this.othersSliceColor)
            return colors
        },
        limitDoughnutChartArcs(data, limit = 8) {
            const orderedData = orderBy(data, 'value', ['desc'])
            if (data.length <= limit) return orderedData

            const slices = orderedData.splice(0, limit - 1)
            const othersSliceValue = orderedData.reduce((total, current) => total + current.value, 0)
            slices.push({ name: 'Others', value: othersSliceValue })

            return slices
        },
        chartDataByDate(data, additive = true) {
            const resolution = this.findBarChartGroupingResolution(data)

            return Object.keys(data).reduce((total, day) => {
                const date = DateTime.fromISO(day)
                let currentDayLabel = ''

                switch (resolution) {
                    case 'daily':
                        currentDayLabel = date.toFormat(this.$appConfig?.VUE_APP_DAYFORMAT)
                        break
                    case 'weekly':
                        currentDayLabel = date.startOf('week').toFormat(this.$appConfig?.VUE_APP_WEEKFORMAT)
                        break
                    case 'monthly':
                        currentDayLabel = date.startOf('month').toFormat(this.$appConfig?.VUE_APP_MONTHFORMAT)
                }

                if (!total[currentDayLabel]) total[currentDayLabel] = 0
                additive ? (total[currentDayLabel] += data[day]) : (total[currentDayLabel] = data[currentDayLabel])

                return total
            }, {})
        },
        hasData(data) {
            if (!data) return
            return Object.entries(data).length > 0
        },
        async fetchChartData(chartName) {
            if (chartName === 'fs_usage_limit' && this.oid) return

            this.fetchingCharts[chartName] = true
            this.charts[chartName] = {}
            this.fsUtilizationDate = ''
            try {
                const [startDate, endDate] = this.dates
                const payload =
                    chartName === 'fs_utilization'
                        ? { date: endDate }
                        : {
                              start_date: startDate,
                              end_date: endDate
                          }

                // if (chartName === 'ncu_utilization') {
                //     payload = 'blablabla'
                // }
                const { data } = await this.$axios.post(`${this.reportURL}/${chartName}`, payload)

                if (chartName === 'fs_utilization') {
                    this.charts[chartName] = data.data
                    this.fsUtilizationDate = data.date
                } else {
                    this.charts[chartName] = data
                }
            } catch (error) {
                console.log(error)
                this.charts[chartName] = error
            } finally {
                this.fetchingCharts[chartName] = false
            }
        },
        fetchAllCharts() {
            Object.keys(this.charts).forEach(chartName => {
                this.fetchChartData(chartName)
            })
        }
    }
}
</script>

<style scoped lang="scss">
@import '@/sass/dashboard.scss';
</style>
